import { WidgetTypes, useCreatePay } from '../..';
import React from 'react';
import { Payment } from 'shared';
import { Modal } from 'shared/_ui';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

type PaymentModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentPayment: Payment | null;
};

const PaymentModal: React.FC<PaymentModalProps> = ({
  currentPayment,
  isOpen,
  setIsOpen,
}) => {
  const { handleClose } = useCreatePay({
    currentPayment,
    isOpen,
    setIsOpen,
  });

  const navigate = useNavigate();
  
  React.useEffect(
    () => {
      window.addEventListener("message", function(e) { //Отправку смотреть в SuccessfulPaymentPage
        if(e.data === "sc-payment") navigate("/successful-payment");
      });
    }, []
  )
  

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.PAYCOS && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.OXPROCESSING && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.PAYZE && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.widgetType &&
        currentPayment.widgetType === WidgetTypes.U_KASSA && (
          <div id="modalUkass" className={styles.container}>
            <div id="payment-form"></div>
          </div>
        )}
      {currentPayment?.widgetType &&
        currentPayment.widgetType === WidgetTypes.RU_CLOUDPAYMENTS && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
    </Modal>
  );
};

export { PaymentModal };
