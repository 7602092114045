import React from 'react';
import {
  notify,
  Payment,
  PaymentStatus,
  setBodyOverflow,
  useGetPaymentTariffRefresh,
} from 'shared';
import { useTranslation } from 'react-i18next';

interface Props {
  setIsOpen: (newIsOpenState: boolean) => void;
  currentPayment: Payment | null;
  isOpen: boolean;
}

type ScriptStatus = 'ready' | 'error' | 'notLoaded';

type MapFunc = { [key: string]: () => void };

const DELAY = 5000;
const DELAY_CLOSE_OVERLAY = 500;

export enum WidgetTypes {
  CLOUDPAYMENTS = 'CLOUDPAYMENTS',
  U_KASSA = 'U_KASSA',
  PAYCOS = 'PAYCOS',
  OXPROCESSING = 'OXPROCESSING',
  PAYZE = 'PAYZE',
  RU_CLOUDPAYMENTS = 'RU_CLOUDPAYMENTS',
}

export const useCreatePay = ({ setIsOpen, currentPayment, isOpen }: Props) => {
  const { t } = useTranslation();
  const [paymentStatus, setPaymentStatus] =
    React.useState<PaymentStatus>('IN_PROGRESS');
  const [configCreated, setConfigCreated] = React.useState(false);
  const [scriptStatus, setScriptStatus] =
    React.useState<ScriptStatus>('notLoaded');
  const [isLoading, setIsLoading] = React.useState(true);
  const [getPaymentStatus] = useGetPaymentTariffRefresh();
  const timer = React.useRef<number | null>(null);
  const handleScript = (e: Event) => {
    setIsLoading(false);
    setScriptStatus(e.type === 'load' ? 'ready' : 'error');
  };

  const handleClose = React.useCallback(() => {
    if (scriptStatus) {
      timer.current && clearTimeout(timer.current);
      setIsLoading(true);
      setPaymentStatus('IN_PROGRESS');
      setScriptStatus('notLoaded');
      setConfigCreated(false);
      const widgetScript = document.getElementById('widget_vpn');
      if (widgetScript) {
        widgetScript.remove();
      }
      const modalContent = document.getElementById('modalUkass');
      modalContent && modalContent.classList.add('close-modal-state');
      setTimeout(() => {
        setIsOpen(false);
        setBodyOverflow(false);
        setIsLoading(false);
      }, DELAY_CLOSE_OVERLAY);
    }
  }, [scriptStatus]);

  let checkStatus = React.useCallback(() => {
    setTimeout(() => {
      console.log('initial checkStatus');
    }, DELAY);
  }, []);

  const handleGetPaymentStatus = React.useCallback(() => {
    const notifyPayment: MapFunc = {
      SUCCESS: () => notify.success(t('successPay')),
      FAIL: () => notify.error(t('errorRequest')),
    };

    if (currentPayment?.paymentId && paymentStatus === 'IN_PROGRESS') {
      getPaymentStatus(currentPayment.paymentId)
        .unwrap()
        .then((data) => {
          setPaymentStatus(data.paymentStatus);
          if (data.paymentStatus === 'SUCCESS') {
            notifyPayment[data.paymentStatus]();
            window.parent.postMessage("sc-payment")
            handleClose();
          }
          if (data.paymentStatus === 'FAIL') {
            notifyPayment.FAIL();
            handleClose();
          }
          if (data.paymentStatus === 'IN_PROGRESS') {
            checkStatus();
          }
        })
        .catch(() => {
          notifyPayment.FAIL();
          setPaymentStatus('FAIL');
          handleClose();
        });
    }
  }, [
    currentPayment,
    getPaymentStatus,
    handleClose,
    paymentStatus,
    t,
    checkStatus,
  ]);

  checkStatus = () => {
    timer.current = window.setTimeout(() => {
      handleGetPaymentStatus();
    }, DELAY);
  };

  React.useEffect(() => {
    const configCloudPayments = () => {
      if (currentPayment?.token && !configCreated) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const payments = new cp.CloudPayments();
        payments.pay(
          'charge',
          {
            publicId: currentPayment?.token, // 'test_api_00000000000000000000002',
            description: '',
            amount: currentPayment?.amount,
            currency: currentPayment?.currency,
            invoiceId: currentPayment?.paymentId, // 1234567,
            data: currentPayment?.receiptData,
          },
          {
            onSuccess: function () {
              setIsLoading(true);
              checkStatus();
            },
            onFail: function () {
              setPaymentStatus('FAIL');
              notify.error(t('errorRequest'));
              handleClose();
            },
          }
        );
      }
    };
    const configUKassa = () => {
      if (currentPayment?.token && !configCreated) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: currentPayment?.token,
          error_callback: function () {
            setPaymentStatus('FAIL');
            notify.error(t('errorRequest'));
            handleClose();
          },
        });
        checkout.render('payment-form');
        checkout.on('complete', () => {
          setIsLoading(true);
          checkStatus();
          checkout.destroy();
        });
        setConfigCreated(true);
      }
    };

    const configPaycos = () => {
      if (currentPayment?.paymentId && !configCreated) {
        setConfigCreated(true);
        checkStatus();
      }
    };
    const configOxPressing = () => {
      if (currentPayment?.paymentId && !configCreated) {
        setConfigCreated(true);
        checkStatus();
      }
    };
    const configPayze = () => {
      if (currentPayment?.paymentId && !configCreated) {
        setConfigCreated(true);
        checkStatus();
      }
    };

    const widgetTypeMap: MapFunc = {
      CLOUDPAYMENTS: () => configCloudPayments(),
      U_KASSA: () => configUKassa(),
      PAYCOS: () => configPaycos(),
      OXPROCESSING: () => configOxPressing(),
      PAYZE: () => configPayze(),
    };
    const configWidget = () => {
      if (currentPayment?.widgetType) {
        widgetTypeMap[currentPayment.widgetType]();
      }
    };
    if (scriptStatus === 'ready' && currentPayment?.widgetType) {
      configWidget();
    }
    if (scriptStatus === 'error') {
      notify.error(t('errorLoadScript'));
      handleClose();
    }
  }, [
    t,
    scriptStatus,
    configCreated,
    currentPayment,
    checkStatus,
    handleClose,
  ]);

  React.useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const script = document.getElementById('widget_vpn');
      if (currentPayment?.widgetType === WidgetTypes.PAYCOS) {
        setScriptStatus('ready');
      } else if (currentPayment?.widgetType === WidgetTypes.OXPROCESSING) {
        setScriptStatus('ready');
      } else if (currentPayment?.widgetType === WidgetTypes.PAYZE) {
        setScriptStatus('ready');
      } else {
        script && script.addEventListener('load', handleScript);
      }
    } else {
      handleClose();
    }
  }, [isOpen, currentPayment?.widgetType, scriptStatus, handleClose]);

  return {
    handleClose,
    isLoading,
  };
};
